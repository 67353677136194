import { USER_ADDRESS } from "../constants";
import { eggAbi } from "../constants/eggAbi";
import { useState, useEffect } from 'react';
import { connex, eggContract } from "../constants/contract"
import BigNumber from "bignumber.js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import { css } from "@emotion/react";
import dosEggs from "../images/ezgif-5-387196807e-16.gif"
import {findMethodABI, getReceipt} from "../utilities/util";

const Minting = () => {
    const [totalSupply, setTotalSupply] = useState(0);
    const [maxEntries, setMaxEntries] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [mintAmount, setMintAmount] = useState(1);
    const [period, setPeriod] = useState(0);
    const [loading, setLoading] = useState(true);
    const [mintsLeft, setmintsLeft] = useState(0)

    useEffect(async () => {
        if (!USER_ADDRESS) {
            toast.error('Connect your wallet!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });

        }
        await initialLoad();
    }, [])

    const initialLoad = async () => {
        const totalSupplyABI = findMethodABI(eggAbi, 'maxSupply');
        const totalSupplyMethod = await eggContract.method(totalSupplyABI).call();
        setMaxEntries(totalSupplyMethod.decoded[0]);

        const totalMintedABI = findMethodABI(eggAbi, 'totalSupply');
        const totalMintedMethod = await eggContract.method(totalMintedABI).call();
        setTotalSupply(parseInt(totalMintedMethod.decoded[0]));

        const getCurrentPriceABI = findMethodABI(eggAbi, 'getCurrentPrice');
        const getCurrentPriceMethod = await eggContract.method(getCurrentPriceABI).call();
        setMintPrice(parseInt(getCurrentPriceMethod.decoded[0]));

        const getCurrentPeriodABI = findMethodABI(eggAbi, 'getCurrentPeriod');
        const getCurrentPeriodMethod = await eggContract.method(getCurrentPeriodABI).call();
        setPeriod(parseInt(getCurrentPeriodMethod.decoded[0]));

        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 0 && USER_ADDRESS) {
            const getOnWlABI = findMethodABI(eggAbi, 'whitelistedPeriod1');
            const getOnWLMethod = await eggContract.method(getOnWlABI).call(USER_ADDRESS);
            setmintsLeft(getOnWLMethod.decoded[0]);
        }
        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 1 && USER_ADDRESS) {
            const getOnWlABI = findMethodABI(eggAbi, 'whitelistedPeriod2');
            const getOnWLMethod = await eggContract.method(getOnWlABI).call(USER_ADDRESS);
            setmintsLeft(getOnWLMethod.decoded[0]);
        }
        if (parseInt(getCurrentPeriodMethod.decoded[0]) === 2) {
            setmintsLeft(10);
        }
        setLoading(false);
    }

    const mint = async () => {
        if (!USER_ADDRESS) {
            toast.error('Connect your wallet!', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined,
            });

            return;
        }

        const mintABI = findMethodABI(eggAbi, 'mint');
        const mintMethod = eggContract.method(mintABI).caller(USER_ADDRESS);

        const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed();
        const clause = mintMethod.value(totalPrice).asClause(mintAmount)

        const resp = await connex.
            vendor.
            sign('tx', [clause]).
            comment('Transaction signing - Mint NFT').
            accepted(() => console.log('accepted')).
            request()
            ;

        setLoading(true);

        let receipt = null;
        while (receipt == null) {
            receipt = await getReceipt(resp.txid);
            await sleep(1000);
        }

        toast.success('Minting succeeded!', {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
        });

        setmintsLeft(0)
        await initialLoad();
        setLoading(false);
    }

    const sleep = m => new Promise(r => setTimeout(r, m))

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    if (loading) {
        return <ClipLoader color={'#0000000'} loading={loading} css={override} size={40} />
    }

    import('./../Minting.css')

    return <section className="u-clearfix u-palette-2-dark-3 u-section-1" id="sec-2990">
        <div className="u-clearfix u-sheet u-sheet-1">
            <h1 className="u-align-center u-custom-font u-text u-text-1">Welcome to the research area</h1>
            <h3 className="u-align-center u-custom-font u-text u-text-2">Are you ready to discover your egg ? Start your journey into Singapura now !</h3>
            <img className="u-image u-image-default u-image-1" src={dosEggs} alt="" data-image-width={781} data-image-height={1200} />
            <p className="u-align-center u-custom-font u-text u-text-3">Dragon Eggs left : {maxEntries - totalSupply} / {maxEntries}</p>
            <p className="u-align-center u-custom-font u-font-oswald u-text u-text-4">
                Start Presale Shur'tugal : 28th of May 2022 12:00 PM UTC<br />
                - Max 3 Dragon Eggs for 300 VET<br />
                Start Presale Whitelisted Members&nbsp; : 28th of May 2022 00:00 AM UTC<br />
                -Max 1 Dragon Egg for 400 VET<br />
                Start Public Sale : 29th of May 2022 12:00 PM UTC<br />
                - 500 VET per Dragon Egg 10 Per TX
            </p>
            <div className={"u-align-center u-custom-font u-font-oswald u-text u-text-5"}>
                <input disabled={mintsLeft <= 0 ? 1 : 0} type="number" value={mintAmount} min={1} max={mintsLeft} onChange={num=>setMintAmount(Number(num.target.value))} name="Amount"  className={"u-shape u-shape-rectangle u-white u-shape-1"} />
            </div>

            <h5 className="u-align-center u-custom-font u-font-oswald u-text u-text-5">Current Price : {(mintPrice * 1) > 0 ? (mintPrice * 1) / ("1e" + 18) + ' VET' : 'Minting disabled' }</h5>
            <p className="u-align-center u-custom-font u-font-oswald u-text u-text-6">How many Dragon Eggs can you handle ?</p>
            <p className="u-align-center u-custom-font u-font-oswald u-text u-text-7">Total Price :  {(mintPrice * 1) > 0 ? (mintPrice * 1) / ("1e" + 18) * mintAmount + ' VET' : 'Minting disabled' }</p>
            <button onClick={mintsLeft ? mint : null} disabled={mintsLeft <= 0 ? 1 : 0} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{mintsLeft <= 0 ? 'Minting disabled' : 'Mint now'} </button>
            <p className="u-align-center u-custom-font u-font-oswald u-text u-text-8">
                After Minting your Dragon Eggs. You will be able to find them in the "
                <a href="/assets" data-page-id={872030705} className="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">Your Dragon Eggs</a>" Tab or on "
                <a href="https://blackvemarket.com/" target="_blank" className="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-3">BlackV</a>".
            </p>
        </div>
    </section>
};

export default Minting;
