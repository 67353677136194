import {toast} from "react-toastify";
import {connex} from "../../constants/contract";
import {sleep} from "../../utilities/util";

export class ContractUtils {
    constructor() {
        this.connex = connex;
        this.toast = toast;
    }

    async sign(txType, clauses, comment, setLoading) {
        setLoading(true);

        let resp = null;
        try {
            resp = await this.connex.vendor.sign(
                txType,
                clauses
            ).comment(comment).accepted(() => console.log('accepted')).request();
        } catch (e) {
            toast.error('Transaction signing failed!');
            return;
        }

        const receipt = await this.getReceipt(resp.txid);
        if (receipt.reverted) {
            this.toast.error('Transaction failed!', {
                position: "top-right",
            });
        } else {
            this.toast.success('Transaction succeeded!', {
                position: "top-right",
            });
        }

        return true;
    }

    getClauses(tokenIds, method) {
        return tokenIds.map(tokenId => {
            return method.asClause(tokenId);
        });
    }

    async getReceipt(txId) {
        let receipt = null;
        while (receipt == null) {
            receipt = await connex.thor.transaction(txId).getReceipt();
            await sleep(1000);
        }

        return receipt;
    }
}