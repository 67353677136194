// const NODE = 'https://testnet.veblocks.net/'
// const NETWORK = 'test'
// const PROJECT_NAME = 'Dragons of Singapura'
// const EGG_CONTRACT = '0x65518eeed27f113a971e33375c29d22c835ed9b5'
// const EGG_STAKING_CONTRACT = '0xccf527165Ce9872d1cFa61fEE45174E490f19069'
// const DRAGONCOIN_CONTRACT = '0x98ceed8ee645c2d964cc0dd4342c832d52aeeb13'
// const BABY_CONTRACT = '0x580fDfCF492CcEE6EB7203Fe94ef25f0e6a01f89'
// const WARBANDS_CONTRACT = '0xb4a07b0754156e3f4930366ed6b8a43b5666b3c3'
// const WARBANDS_STAKING_CONTRACT = '0x8F072843Cf25D00fAeae4596788F1b7CA969f854'
// const ADDRESS_KEY = 'test_address';

const NODE = 'https://mainnet.veblocks.net/'
const NETWORK = 'main'
const PROJECT_NAME = 'Dragons of Singapura'
const EGG_CONTRACT = '0xd6B546368087D82a230A561c777Ca74776A1BB0C'
const EGG_STAKING_CONTRACT = '0x6368f744862ABC4d70d0714180d6d1902a86aA9B'
const DRAGONCOIN_CONTRACT = '0x107A0B0Faeb58c1fdEF97f37f50e319833ad1b94'
const BABY_CONTRACT = '0xC22D8cA65Bb9EE4A8b64406f3B0405CC1EbeEc4e'
const WARBANDS_CONTRACT = '0x6aA982158617D53C37F65d43eb9a156449aDfFf3'
const WARBANDS_STAKING_CONTRACT = '0x418415d6f036750F65C0168B99B10C73c38c50fa'
const ADDRESS_KEY = 'address';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    EGG_CONTRACT,
    EGG_STAKING_CONTRACT,
    DRAGONCOIN_CONTRACT,
    BABY_CONTRACT,
    USER_ADDRESS,
    WARBANDS_CONTRACT,
    WARBANDS_STAKING_CONTRACT,
    ADDRESS_KEY
}
