import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {ContractUtils} from "./ContractUtils";

export class TokenContract extends ContractUtils {
    async transferTokens(abi, contract, to, _amount, tokenName, setLoading) {
        const amount = ((_amount) * ("1e" + 18)).toString();
        const transferAbi = findMethodABI(abi, 'transfer');
        const transferMethod = contract.method(transferAbi).caller(USER_ADDRESS);

        const resp = await this.connex.vendor.sign(
            'tx',
            [transferMethod.asClause(to, amount)]
        ).comment('Transaction signing - Transfer ' + tokenName).accepted(() => console.log('accepted')).request();

        setLoading(true);

        const receipt = await this.getReceipt(resp.txid);
        if (receipt.reverted) {
            this.toast.error('Transfer of tokens failed!', {
                position: "top-right",
            });
        } else {
            this.toast.success('Transfer of tokens succeeded!', {
                position: "top-right",
            });
        }

        return true;
    }

    async balanceOf(abi, contract) {
        const balanceOfAbi = findMethodABI(abi, 'balanceOf');
        const balanceOfMethod = contract.method(balanceOfAbi);

        return (await balanceOfMethod.call(USER_ADDRESS)).decoded[0];
    }
}