import {toast} from "react-toastify";

export const batchCheck = (type, batchTokenIds, setBatchTokenIds, batchAction, setBatchAction) => {
    if (batchAction !== type) {
        resetBatch(type, setBatchTokenIds, setBatchAction);
        return false;
    }

    if (batchTokenIds.length === 0) {
        setBatchAction(type);
        toast.error('No tokens selected');
        return false;
    }

    resetBatch(type, setBatchTokenIds, setBatchAction);

    return true;
}

export const resetBatch = (type, setBatchTokenIds, setBatchAction) => {
    setBatchTokenIds([]);
    setBatchAction(type);
    resetCards();
}

export const selectToken = (e, index, token, setBatchTokenIds, batchTokenIds, type) => {
    if (e.target.tagName.toLowerCase() === 'a') {
        return;
    }

    const card = document.getElementById('card-' + index);
    const button = document.getElementById(type + '-button');
    if (button === null) {
        return;
    }

    if (card.style.borderColor === 'yellow') {
        setBatchTokenIds(batchTokenIds.filter(id => id !== token.result.edition))
        button.innerText = 'Batch ' + type + ' (' + parseInt(batchTokenIds.length - 1) + '/25)';
        card.style.borderColor = '#83e686';
        card.style.boxShadow = 'none';
    } else {
        if (batchTokenIds.length === 25) {
            return;
        }

        setBatchTokenIds([...batchTokenIds, token.result.edition])
        button.innerText = 'Batch ' + type + ' (' + parseInt(batchTokenIds.length + 1) + '/25)';
        card.style.borderColor = 'yellow';
        card.style.boxShadow = '0px 0px 30px #83e686';
    }
}

export const resetCards = () => {
    const cards = document.getElementsByClassName('cards');
    for (let i = 0; i < cards.length; i++) {
        cards[i].style.borderColor = '#83e686';
        cards[i].style.boxShadow = 'none';
    }

    const buttons = document.getElementsByClassName('u-btn-batch');
    for (let i = 0; i < buttons.length; i++) {
        let buttonTitle = buttons[i].id;
        buttonTitle = buttonTitle.replace('-button', '');

        buttons[i].innerText = 'Batch ' + buttonTitle + ' (0/25)';
    }
}
