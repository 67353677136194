import Connex from '@vechain/connex'
import {
    NODE,
    NETWORK,
    EGG_CONTRACT,
    EGG_STAKING_CONTRACT,
    DRAGONCOIN_CONTRACT,
    BABY_CONTRACT,
    WARBANDS_CONTRACT,
    WARBANDS_STAKING_CONTRACT
} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const eggContract = connex.thor.account(EGG_CONTRACT)
const eggStakingContract = connex.thor.account(EGG_STAKING_CONTRACT)
const dragonCoinContract = connex.thor.account(DRAGONCOIN_CONTRACT)
const babyContract = connex.thor.account(BABY_CONTRACT)
const warbandsContract = connex.thor.account(WARBANDS_CONTRACT)
const warbandsStakingContract = connex.thor.account(WARBANDS_STAKING_CONTRACT)

export {
    connex,
    eggContract,
    eggStakingContract,
    dragonCoinContract,
    babyContract,
    warbandsContract,
    warbandsStakingContract
}
