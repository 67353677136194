import {ContractUtils} from "./ContractUtils";
import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";

export class NftContract extends ContractUtils {
    async walletOfOwner(abi, contract) {
        const walletOfOwnerAbi = findMethodABI(abi, 'walletOfOwner')
        const walletOfOwnerMethod = contract.method(walletOfOwnerAbi);

        return (await walletOfOwnerMethod.call(USER_ADDRESS)).decoded[0];
    }

    async transfer(abi, contract, to, tokenId, setLoading, loadAssets) {
        const transferFromAbi = findMethodABI(abi, 'transferFrom')
        const transferFromMethod = contract.method(transferFromAbi);

        await this.sign(
            'tx',
            [transferFromMethod.asClause(USER_ADDRESS, to, tokenId)],
            'Transaction signing - Transfer NFT',
            setLoading
        );

        return await loadAssets();
    }

    async setApprovalForAll(abi, contract, stakingContract, setLoading, initialize) {
        const setApprovalForAllAbi = findMethodABI(abi, 'setApprovalForAll')
        const setApprovalForAllMethod = contract.method(setApprovalForAllAbi);

        await this.sign(
            'tx',
            [setApprovalForAllMethod.asClause(stakingContract.address, true)],
            'Transaction signing - Set approval for all',
            setLoading
        );

        return await initialize();
    }

    async isApprovedForAll(abi, contract, stakingContract) {
        const isApprovedForAllAbi = findMethodABI(abi, 'isApprovedForAll')
        const isApprovedForAllMethod = contract.method(isApprovedForAllAbi);

        return (await isApprovedForAllMethod.call(USER_ADDRESS, stakingContract.address)).decoded[0];
    }
}
