import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home'
import Minting from './pages/Minting'
import Assets from './pages/Assets'
import NoPage from "./pages/NoPage";
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Faq from './pages/Faq';
import Hatch from './pages/Hatch';
import Treasury from "./pages/Treasury";
import Warbands from "./pages/Warbands";
import BabyDragons from "./pages/BabyDragons";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< Minting />} path='/minting'/>
                <Route element={< Assets />} path='/assets'/>
                <Route element={< Faq />} path='/faq'/>
                <Route element={< Treasury />} path='/treasury'/>
                <Route element={< Warbands />} path='/warbands'/>
                <Route element={< BabyDragons />} path='/babydragons'/>
                <Route element={< Hatch />} path='/hatch'/>
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
